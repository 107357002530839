#terminalDialog {
  position : fixed;
  background-color: rgba(0, 0, 0, 90%);
  font-size : 18px;
  color: #0f0;
  resize: both;
  overflow: hidden;
  width : 60%;
  height : 70%;
  border-radius : 10px;
  box-shadow:  0px 0px 5px 1px black;
}


.terminalScreen {
  display : block;
  width : 100%;
  height : 92%;
  font-size: 16px;
}


@media only screen and (max-width: 800px) {
#terminalDialog {
  width  : 75%;
  height : 75%;
}
}


@media only screen and (max-width: 500px) {
#terminalDialog {
  width  : 85%;
  height : 85%;
}

#title {
  font-size: 14px;
  margin-left: 36px;
}
}


@media only screen and (max-width: 400px) {
#terminalDialog {
  width  : 95%;
  height : 95%;
}
}

h1, h2{
  display: none;
}